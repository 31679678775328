import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

type LinkTitle = {
  first: string;
  firstlink: string;
  second: string;
};

export const BreadComp: React.FC<LinkTitle> = ({
  first,
  firstlink,
  second,
}) => {
  return (
    <Breadcrumb style={{ margin: "30px 0" }}>
      <Breadcrumb.Item>
        <Link to="/temple">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={firstlink}>{first}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{second}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
