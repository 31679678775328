import React, { useEffect, useState } from "react";
import { Button, Layout } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { TempleList } from "../components/temple/TempleList";
import { TempleApi } from "../components/common/commonApi";
import { BreadComp } from "../components";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Temple: React.FC = () => {
  const [data, setData] = useState([]);

  const fetchList = () => {
    TempleApi()
      .then((res) => {
        const { results = [] } = res;
        setData(results);
        // var filterdata = results.filter((p) => {
        //   return p.owner === sessionStorage.getItem("userid");
        // });
        // if (results.length > 0) {
        //   if (window.localStorage.getItem("isStaff") === "false") {
        //     setData(filterdata);
        //   } else {
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteTemple = (id) => {
    const result = window.confirm("정말 삭제하시겠습니까?");

    if (result) {
      adminAxios({
        method: "delete",
        url: `buddhism/${id}`,
      })
        .then((res) => {
          alert("삭제하였습니다.");
          setData([]);
          fetchList();
        })
        .catch((err) => {
          console.log(err);
          alert("삭제에 실패하였습니다.");
        });
    }
  };

  const handleRefresh = () => {
    fetchList();
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="사찰 관리"
          firstlink="/"
          second="사찰리스트"
        ></BreadComp>
        <Button style={{ marginBottom: 10 }} onClick={handleRefresh}>
          새로고침
        </Button>
        {data.length === 0 ? (
          <Link to="/templeadd" className="dummy" style={{ float: "right" }}>
            <Button style={{ marginBottom: 10 }}>사찰 추가</Button>
          </Link>
        ) : null}
        <TempleList data={data} onDeleteTemple={handleDeleteTemple} />
      </Content>
    </PageContainer>
  );
};

export default Temple;
