import React from "react";
import { Card, Layout } from "antd";
import { PageContainer } from "../components/common/PageContainer";
import { BreadComp } from "../components";
import BannerAddForm from "../components/banner/BannerAddForm";

const BannerAdd: React.FC = () => {
  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="배너"
          firstlink="/banner"
          second="배너 추가"
        ></BreadComp>
        <Card>
          <BannerAddForm />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default BannerAdd;
