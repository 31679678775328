import React, { useEffect, useState } from "react";
import { Layout, Typography, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import adminAxios from "../../utils/adminAxios";
import SideMenu from "../sidemenu/";
import temp from "../../assets/images/logo3.png";
import "./index.css";

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

export const PageContainer: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      adminAxios.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem("token");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
    } else {
      alert("로그인을 해주세요!");
      handleLogout();
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    adminAxios({
      method: "get",
      url: "user?limit=500&offset=10",
    })
      .then((res) => {
        const data = res.data;
        const { results = [] } = data;
        if (results.length > 1) {
          window.localStorage.setItem("isStaff", "true");
        } else {
          window.localStorage.setItem("user", data.id);
          window.localStorage.setItem("isStaff", "false");
        }
        if (results.length > 0) {
          setData(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Layout>
        <Header className="header">
          <img src={temp} className="logo" />
          <div style={{ float: "right" }}>
            <Button onClick={handleLogout}>로그아웃</Button>
          </div>
        </Header>
        <Layout>
          <SideMenu></SideMenu>

          <Layout style={{ padding: "0 24px 24px" }}>{children}</Layout>
        </Layout>
      </Layout>
    </div>
  );
};
