import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Tabs, Select } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { OrderList } from "../components/order/OrderList";
import { OrderApi } from "../components/common/commonApi";
import { BreadComp } from "../components";
import { StaticTempleList } from "../components/statics/StaticsTempleList";
import moment from "moment";

const { Content } = Layout;

const StaticsTemple: React.FC = () => {
  var today = new Date();
  const [data, setData] = useState([]);
  const [yeardata, setYeardata] = useState(today.getFullYear());
  const [monthdata, setMonthdata] = useState(today.getMonth() + 1);

  useEffect(() => {
    OrderApi().then((res) => {
      const { results = [] } = res;
      var filterdata = results.filter((p) => {
        return p.store.owner === sessionStorage.getItem("userid");
      });
      if (window.localStorage.getItem("isStaff") === "false") {
        setData(filterdata);
      } else {
        setData(results);
      }
    });
  }, []);

  const result_year = data.reduce((acc, curr) => {
    const Convert_date = new Date(curr.date);
    const year = Convert_date.getFullYear();

    if (acc[year]) acc[year].push(curr);
    else acc[year] = [curr];
    return acc;
  }, {});

  const handleChangeYear = (value) => {
    setYeardata(value);
  };
  const handleChangeMonth = (value) => {
    setMonthdata(value);
  };

  const filterdata = data.filter((p) => {
    const convertDate = new Date(p.date);
    const checkDateStart = new Date(yeardata, monthdata, 1, 0, 0);
    const checkDateEnd = new Date(yeardata, monthdata, 31, 0, 0);

    console.log(moment(convertDate, "YYYYMMDD"));

    return (
      moment(convertDate, "YYYYMMDD") >= moment(checkDateStart, "YYYYMMDD") &&
      moment(convertDate, "YYYYMMDD") <= moment(checkDateEnd, "YYYYMMDD")
    );
  });

  const result = filterdata.reduce((acc, curr) => {
    if (acc[curr.store.name]) acc[curr.store.name].push(curr);
    else acc[curr.store.name] = [curr];
    return acc;
  }, []);

  const items = [
    {
      key: "1",
      label: `월별`,
      children: (
        <StaticTempleList
          data={Object.entries(result)}
          type="month"
        ></StaticTempleList>
      ),
    },
  ];

  const month = [
    { label: "1월", value: 1 },
    { label: "2월", value: 2 },
    { label: "3월", value: 3 },
    { label: "4월", value: 4 },
    { label: "5월", value: 5 },
    { label: "6월", value: 6 },
    { label: "7월", value: 7 },
    { label: "8월", value: 8 },
    { label: "9월", value: 9 },
    { label: "10월", value: 10 },
    { label: "11월", value: 11 },
    { label: "12월", value: 12 },
  ];

  const year = [
    { label: "2020년", value: 2020 },
    { label: "2021년", value: 2021 },
    { label: "2022년", value: 2022 },
    { label: "2023년", value: 2023 },
    { label: "2024년", value: 2024 },
    { label: "2025년", value: 2025 },
    { label: "2026년", value: 2026 },
    { label: "2027년", value: 2027 },
    { label: "2028년", value: 2028 },
    { label: "2029년", value: 2029 },
    { label: "2030년", value: 2030 },
    { label: "2031년", value: 2031 },
    { label: "2032년", value: 2032 },
  ];

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="통계"
          firstlink="/order"
          second="사찰별 통계 리스트"
        ></BreadComp>
        <div style={{ textAlign: "center" }}>
          <Select
            defaultValue={today.getFullYear()}
            style={{
              width: 150,
              marginRight: 10,
            }}
            key={"year"}
            onChange={handleChangeYear}
            options={[
              {
                label: "연도",
                options: year,
              },
            ]}
          />
          <Select
            defaultValue={today.getMonth() + 1}
            style={{
              width: 100,
            }}
            key={"month"}
            onChange={handleChangeMonth}
            options={[
              {
                label: "월별",
                options: month,
              },
            ]}
          />
        </div>
        <Tabs
          //onChange={onChange}
          type="card"
          items={items}
        />
      </Content>
    </PageContainer>
  );
};

export default StaticsTemple;
