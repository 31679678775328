import React, { useEffect } from "react";
import { Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { SignInForm } from "../components/signin";
import adminAxios from "../utils/adminAxios";

const { Header, Footer, Sider, Content } = Layout;
const { Text, Link } = Typography;

const SignIn: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      adminAxios.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem("token");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
      navigate("/temple");
    }
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Layout>
        <Header>
          <Text type="success">비나이다 어드민</Text>
        </Header>
        <Layout>
          <Content>
            <SignInForm />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default SignIn;
