import { Breadcrumb, Button, Layout } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "../components/common/PageContainer";
import NoticeList from "../components/notice/NoticeList";
import adminAxios from "../utils/adminAxios";
import { NoticesRs } from "../model/noticesRs";
import { BreadComp } from "../components";
import { Link } from "react-router-dom";

const Notice: React.FC = () => {
  const [data, setData] = useState([]);

  const readNotices = useCallback(() => {
    adminAxios
      .get<NoticesRs>("notice/", { params: { limit: 9999, offset: 0 } })
      .then((res) => {
        setData(res.data.results ?? []);
      });
  }, []);

  const handleRefresh = useCallback(() => {
    readNotices();
  }, [readNotices]);

  useEffect(() => {
    readNotices();
  }, []);

  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="공지사항"
          firstlink="/notice"
          second="공지사항 리스트"
        ></BreadComp>
        <Button style={{ marginBottom: 10 }} onClick={handleRefresh}>
          새로고침
        </Button>
        <Link to="/noticeadd" className="dummy" style={{ float: "right" }}>
          <Button style={{ marginBottom: 10 }}>공지사항 추가</Button>
        </Link>
        <NoticeList
          data={data}
          onDelete={() => {
            readNotices();
          }}
        />
      </Layout.Content>
    </PageContainer>
  );
};

export default Notice;
