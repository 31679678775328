import { Form, Input, Button, Space, Card } from "antd";
import axios from "axios";
import adminAxios from "../../utils/adminAxios";
import { useNavigate } from "react-router-dom";
import img01 from "./01.png";
import img02 from "./02.png";

const setSession = (token: string) => {
  const sStorage = window.sessionStorage;
  sStorage.setItem("token", token);
};

export const SignInForm = () => {
  const navigate = useNavigate();

  const onFinish = (values: { username?: string; password?: string }) => {
    console.log("Success:", values);
    const { username = "", password = "" } = values;

    axios({
      method: "post",
      url: "http://ec2-13-209-28-211.ap-northeast-2.compute.amazonaws.com:8001/api/auth/sign-in/",
      //url: "http://210.219.244.78:8000/api/auth/sign-in/",
      data: {
        username,
        password,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.role === "Normal") {
          alert(
            "사찰 관리자만 이용이 가능합니다. \n관리자에게 문의 부탁드리겠습니다."
          );
        } else {
          console.log(res.data.accessToken);
          setSession(res.data.accessToken);
          window.sessionStorage.setItem("userid", res.data.userid);
          window.sessionStorage.setItem("username", username);
          window.localStorage.setItem("isStaff", "false");
          adminAxios.interceptors.request.use(function (config) {
            const token = sessionStorage.getItem("token");
            config.headers.Authorization = token ? `Bearer ${token}` : "";
            return config;
          });
          navigate("/temple");
        }
      })
      .catch((err) => {
        alert("로그인에 실패하였습니다" + JSON.stringify(err));
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Space align="center" direction="vertical" size="large">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ paddingTop: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="아이디"
          name="username"
          rules={[{ required: true, message: "아이디를 입력해주세요!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="비밀번호"
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해주세요!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            로그인
          </Button>
        </Form.Item>
      </Form>
      <Card title="로그인이 되지않을때" style={{ marginLeft: 100 }}>
        <div>로그인이 되지않을때 (크롬)</div>
        <div>
          1. 주소창 상단 자물쇠 아이콘을 클릭 후 사이트 설정을 클릭합니다.
        </div>
        <img
          src={img01}
          alt="1. 주소창 상단 자물쇠 아이콘을 클릭 후 사이트 설정을 클릭합니다."
        />
        <div>2. 안전하지 않는 콘텐츠 값을 허용으로 변경합니다.</div>
        <img
          src={img02}
          alt="2. 안전하지 않는 콘텐츠 값을 허용으로 변경합니다."
        />
      </Card>
    </Space>
  );
};
