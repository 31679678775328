import React, { useCallback } from "react";
import { Button, Table } from "antd";
import dateFormat from "dateformat";
import { NoticeItemRs } from "../../model/noticesRs";
import { Link } from "react-router-dom";
import adminAxios from "../../utils/adminAxios";

const columns = (onDelete: (id: string) => void) => {
  return [
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      render: (id, row) => <Link to={`/notice/${row.id}`}>{id}</Link>,
    },
    {
      title: "내용",
      dataIndex: "contents",
      key: "contents",
    },
    {
      title: "등록일자",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => dateFormat(date, "yyyy.mm.dd hh:MM:ss"),
    },
    {
      title: "삭제",
      dataIndex: "id",
      key: "act",
      render: (id) => (
        <Button type="primary" onClick={() => onDelete(id)} danger>
          삭제
        </Button>
      ),
    },
  ];
};

interface Props {
  data: NoticeItemRs[];

  onDelete(): void;
}

const NoticeList: React.FC<Props> = ({ data, onDelete }) => {
  const handleDelete = useCallback(
    (id: string) => {
      if (
        window.confirm("삭제하시겠습니까?") &&
        window.confirm("정말 삭제하시겠습니까?")
      ) {
        adminAxios.delete(`notice/${id}`).then(() => {
          alert("삭제되었습니다.");
          onDelete();
        });
      }
    },
    [onDelete]
  );

  return <Table columns={columns(handleDelete)} dataSource={data} />;
};

export default NoticeList;
