import React from "react";
import { Breadcrumb, Card, Layout } from "antd";
import { PageContainer } from "../components/common/PageContainer";
import SectAddForm from "../components/sect/SectAddForm";

const SectAdd: React.FC = () => {
  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>종파</Breadcrumb.Item>
          <Breadcrumb.Item>종파추가</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <SectAddForm />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default SectAdd;
