import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "../components/common/PageContainer";
import { Breadcrumb, Button, Card, Form, Layout } from "antd";
import adminAxios from "../utils/adminAxios";
import { NoticeItemRs } from "../model/noticesRs";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";

const NoticeDetail: React.FC = () => {
  const [data, setData] = useState<NoticeItemRs | null>(null);
  const navigate = useNavigate();

  const handleModify = useCallback(() => {
    if (data?.id) {
      navigate(`/noticemodify/${data.id}`);
    }
  }, [data?.id, navigate]);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();

    adminAxios.get<NoticeItemRs>(`notice/${id}`).then((rs) => {
      setData(rs.data);
    });
  }, []);

  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>공지사항</Breadcrumb.Item>
          <Breadcrumb.Item>공지사항상세</Breadcrumb.Item>
        </Breadcrumb>
        <Button style={{ marginBottom: 10 }} onClick={handleModify}>
          수정하기
        </Button>
        <Card>
          <Form.Item label={"제목"}>{data?.title}</Form.Item>
          <Form.Item label={"등록일자"}>
            {data?.created_at
              ? dateFormat(data.created_at, "yyyy.mm.dd hh:MM:ss")
              : null}
          </Form.Item>
          <Form.Item label={"내용"}>
            {data?.contents?.split(/\n/)?.map((txt, idx) => (
              <React.Fragment key={idx}>
                {idx > 0 && <br />}
                <div>{txt}</div>
              </React.Fragment>
            ))}
          </Form.Item>
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default NoticeDetail;
