import { TempleItemOptionGroup } from "../../../../model/temple";
import { renderTreeItemOption } from "./treeItemOptionRenderer";
import { Button, Checkbox, Input } from "antd";
import { DeleteOutlined, MinusOutlined } from "@ant-design/icons";

export function renderTreeItemOptionGroup(
  optionGroup: TempleItemOptionGroup,
  groupIdx: number,
  itemIdx: number,
  optionGroupIdx: number,
  onOptionGroupDelete: (optionGroupIdx: number) => void,
  afterChange: () => void
) {
  const handleOptionDelete = (optionIdx: number) => {
    optionGroup?.options?.splice(optionIdx, 1);
    afterChange();
  };

  return {
    key: `${groupIdx}-${itemIdx}-${optionGroupIdx}`,
    title: (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 105 }}>옵션 그룹명 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            value={optionGroup.name}
            placeholder={"옵션 그룹명을 입력해주세요"}
            onChange={(e) => {
              optionGroup.name = e.target.value ?? "";
              afterChange();
            }}
          />
          <Button
            style={{ marginLeft: 10, flexShrink: "0" }}
            onClick={() => {
              onOptionGroupDelete(optionGroupIdx);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </div>
        <div style={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 105 }}>다중선택 가능 :</div>
          <Checkbox
            checked={optionGroup.multiple}
            onChange={(e) => {
              optionGroup.multiple = e.target.checked ?? false;
              afterChange();
            }}
          />
        </div>
      </div>
    ),
    children: [
      ...(optionGroup.options?.map((option, optionIdx) =>
        renderTreeItemOption(
          option,
          groupIdx,
          itemIdx,
          optionGroupIdx,
          optionIdx,
          handleOptionDelete,
          afterChange
        )
      ) ?? []),
      {
        key: `${groupIdx}-${itemIdx}-${optionGroupIdx}-option-add`,
        switcherIcon: <MinusOutlined />,
        title: (
          <div style={{ paddingBottom: 10 }}>
            <Button
              onClick={() => {
                optionGroup.options?.push({
                  name: "새 옵션",
                  price: 0,
                });
                afterChange();
              }}
            >
              옵션 추가
            </Button>
          </div>
        ),
        isLeaf: false,
      },
    ],
    isLeaf: false,
  };
}
