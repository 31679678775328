import React from "react";
import { Routes, Route } from "react-router-dom";

import Banner from "./Banner";
import BannerAdd from "./BannerAdd";
import BannerModify from "./BannerModify";
import Notice from "./Notice";
import NoticeAdd from "./NoticeAdd";
import NoticeDetail from "./NoticeDetail";
import NoticeModify from "./NoticeModify";
import Order from "./Order";
import SectAdd from "./SectAdd";
import SignIn from "./SignIn";
import Temple from "./Temple";
import TempleAdd from "./TempleAdd";
import TempleDetail from "./TempleDetail";
import TempleModify from "./TempleModify";

import User from "./User";
import Statics from "./Statics";
import StaticsTemple from "./StaticsTemple";

const Home: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="temple">
        <Route path="" element={<Temple />} />
        <Route path=":id" element={<TempleDetail />} />
      </Route>
      <Route path="templeadd" element={<TempleAdd />} />
      <Route path="templemodify/:id" element={<TempleModify />} />
      <Route path="user" element={<User />} />
      <Route path="order" element={<Order />} />
      <Route path="statics" element={<Statics />} />
      <Route path="staticstemple" element={<StaticsTemple />} />
      <Route path="notice">
        <Route path={""} element={<Notice />} />
        <Route path={":id"} element={<NoticeDetail />} />
      </Route>
      <Route path="sectadd" element={<SectAdd />} />
      <Route path="noticemodify/:id" element={<NoticeModify />} />
      <Route path="noticeadd" element={<NoticeAdd />} />
      <Route path="banner">
        <Route path={""} element={<Banner />} />
        {/* <Route path={":id"} element={<NoticeDetail />} /> */}
      </Route>
      <Route path="banneradd" element={<BannerAdd />} />
      <Route path="bannermodify/:id" element={<BannerModify />} />
    </Routes>
  );
};

export default Home;
