import React, { useCallback, useState } from "react";
import { Form } from "antd";
import adminAxios from "../../utils/adminAxios";
import { useNavigate } from "react-router-dom";
import TempleForm from "./TempleForm";
import { TempleRq } from "../../model/templeRq";
import { UploadFile } from "antd/lib/upload/interface";
import { TempleItemGroup } from "../../model/temple";

const TempleAddForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [itemGroups, setItemGroups] = useState<TempleItemGroup[]>([]);
  const [itemGroupFileLists, setItemGroupFileLists] = useState<
    UploadFile[][][]
  >([]);

  const handleConfirm = useCallback(
    async (rq: TempleRq) => {
      try {
        await adminAxios.post("buddhism/", rq);

        alert("사찰 추가에 성공하였습니다.");
        navigate("/temple");
      } catch {
        alert("사찰 추가에 실패하였습니다.");
      }
    },
    [navigate]
  );

  return (
    <TempleForm
      form={form}
      fileList={fileList}
      itemGroups={itemGroups}
      itemGroupItemFileLists={itemGroupFileLists}
      confirmText="추가"
      onConfirm={handleConfirm}
      onItemGroupsChange={(groups) => setItemGroups(groups)}
      onFileUpload={(files) => setFileList([...(fileList ?? []), ...files])}
      onFileRemove={(file) => {
        setFileList(fileList?.filter((v) => v.uid !== file.uid) ?? []);
      }}
      onItemFileUpload={(groupIdx, itemIdx, file) => {
        const newFileList = [...itemGroupFileLists];

        if (!newFileList[groupIdx]) newFileList[groupIdx] = [];
        if (!newFileList[groupIdx][itemIdx])
          newFileList[groupIdx][itemIdx] = [];

        newFileList[groupIdx][itemIdx].push(...file);

        setItemGroupFileLists(newFileList);
      }}
      onItemFileDelete={(groupIdx, itemIdx, file) => {
        const newFileList = [...itemGroupFileLists];

        if (newFileList[groupIdx]?.[itemIdx]) {
          newFileList[groupIdx][itemIdx] = newFileList[groupIdx][
            itemIdx
          ].filter((v) => v.uid !== file.uid);
        }

        setItemGroupFileLists(newFileList);
      }}
    />
  );
};

export default TempleAddForm;
