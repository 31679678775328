import React, { useState } from "react";
import { Form, Input, Button, FormInstance } from "antd";
import { SectRq } from "../../model/sectRq";

interface Props {
  form: FormInstance;
  confirmText: string;

  onConfirm(data: SectRq): Promise<void>;
}

const SectForm: React.FC<Props> = ({ form, confirmText, onConfirm }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onFinish = (values: { name: string; color: string }) => {
    setIsDisabled(true);
    onConfirm(values).finally(() => {
      setIsDisabled(false);
    });
  };

  return (
    <Form.Provider>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="종파명"
          name="name"
          rules={[{ required: true, message: "종파명을 입력해주세요" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="색상"
          name="color"
          rules={[{ required: true, message: "색상을 입력해주세요" }]}
        >
          <Input placeholder={"#000000"} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {confirmText}
          </Button>
        </Form.Item>
      </Form>
    </Form.Provider>
  );
};

export default SectForm;
