import { TempleItem } from "../../../../model/temple";
import { renderTreeItemOptionGroup } from "./treeItemOptionGroupRenderer";
import { Button, Input, Upload } from "antd";
import {
  DeleteOutlined,
  FolderAddOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import adminAxios from "../../../../utils/adminAxios";
import { ImageUploadRs } from "../../../../model/imageUploadRs";
import React from "react";
import { UploadFile } from "antd/lib/upload/interface";

const HEADER = { "Content-Type": "multipart/form-data" };

export function renderTreeItem(
  item: TempleItem,
  groupIdx: number,
  itemIdx: number,
  itemFileList: UploadFile[],
  afterChange: () => void,
  onItemDelete: (itemIdx: number) => void,
  onItemFileUpload: (itemIdx: number, file: UploadFile[]) => void,
  onItemFileRemove: (itemIdx: number, file: UploadFile) => void
) {
  const handleOptionGroupDelete = (optionGroupIdx: number) => {
    item?.option_groups?.splice(optionGroupIdx, 1);
    afterChange();
  };

  return {
    key: `${groupIdx}-${itemIdx}`,
    switcherIcon: <FolderOutlined />,
    title: (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 100 }}>아이템명 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            value={item.name}
            placeholder={"아이템명을 입력해주세요"}
            onChange={(e) => {
              item.name = e.target.value ?? "";
              afterChange();
            }}
          />
          <Button
            style={{ marginLeft: 10, flexShrink: "0" }}
            onClick={() => {
              onItemDelete(itemIdx);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </div>
        <div style={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 100 }}>아이템 가격 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            type={"number"}
            placeholder={"아이템 가격을 입력해주세요"}
            value={item.price}
            onChange={(e) => {
              const price = parseInt(e.target.value ?? "");
              item.price = isFinite(price) && !isNaN(price) ? price : null;
              afterChange();
            }}
          />
        </div>
        <div style={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 100 }}>아이템 설명 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            value={item.description}
            placeholder={"아이템 설명을 입력해주세요"}
            onChange={(e) => {
              item.description = e.target.value ?? "";
              afterChange();
            }}
          />
        </div>
        <div style={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 100 }}>아이템 이미지 :</div>
          <Upload
            name={`item-image-${groupIdx}-${itemIdx}`}
            listType="picture"
            accept="image/*"
            fileList={itemFileList}
            customRequest={async (options) => {
              const frm = new FormData();
              frm.set("images", options.file);

              const rs = await adminAxios.post<ImageUploadRs>(
                "common/image/",
                frm,
                { headers: HEADER, params: { parents: "buddhism" } }
              );

              onItemFileUpload(
                itemIdx,
                rs.data?.map((img) => ({
                  uid: img.id,
                  id: img.id,
                  url: img.url,
                  name: img.id,
                }))
              );
            }}
            onRemove={(file) => {
              onItemFileRemove(itemIdx, file);
            }}
            multiple
          >
            <Button>Click to upload</Button>
          </Upload>
        </div>
      </div>
    ),
    children: [
      ...(item.option_groups?.map((optionGroup, optionGroupIdx) =>
        renderTreeItemOptionGroup(
          optionGroup,
          groupIdx,
          itemIdx,
          optionGroupIdx,
          handleOptionGroupDelete,
          afterChange
        )
      ) ?? []),
      {
        key: `${groupIdx}-${itemIdx}-optionGroup-add`,
        switcherIcon: <FolderAddOutlined />,
        title: (
          <div style={{ paddingBottom: 10 }}>
            <Button
              onClick={() => {
                item.option_groups?.push({
                  name: "새 옵션 그룹",
                  multiple: false,
                  options: [],
                });
                afterChange();
              }}
            >
              옵션 그룹 추가
            </Button>
          </div>
        ),
        isLeaf: false,
      },
    ],
    isLeaf: false,
  };
}
