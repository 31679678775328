import React, { useEffect, useState } from "react";
import { TempleItemRs } from "../model/templeRs";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { Breadcrumb, Card, Layout } from "antd";
import TempleModifyForm from "../components/temple/TempleModifyForm";
import { BreadComp } from "../components";

const TempleModify: React.FC = () => {
  const [data, setData] = useState<TempleItemRs | null>(null);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();

    adminAxios.get<TempleItemRs>(`buddhism/${id}/`).then((rs) => {
      setData(rs.data);
    });
  }, []);

  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="사찰 관리"
          firstlink="/temple"
          second="사찰 변경"
        ></BreadComp>
        <Card>
          <TempleModifyForm data={data} />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default TempleModify;
