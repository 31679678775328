import React from "react";
import { Table, Button, Tag } from "antd";
import "moment/locale/ko"; // Locale Setting
import moment from "moment";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import adminAxios from "../../utils/adminAxios";

const addComma = (price) => {
  let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return returnString;
};

export const StaticList = (data) => {
  let daytitle = "";
  if (data.type === "day") daytitle = "일자별";
  if (data.type === "month") daytitle = "월별";
  if (data.type === "year") daytitle = "연도별";
  const columns = [
    {
      title: daytitle,
      dataIndex: "0",
    },
    {
      title: "주문 건수",
      dataIndex: "1",
      render: (text, row: any, index) => {
        if (row[1]) {
          let rowdata = Object.values(row[1]);
          return <>{rowdata.length}</>;
        } else {
          return <>0</>;
        }
      },
    },
    {
      title: "진행중 건수",
      dataIndex: "2",
      render: (text, row: any, index) => {
        if (row[1]) {
          let rowdata = Object.values(row[1]);
          return (
            <>
              {
                rowdata.filter(
                  (p: any) => p.status === "PROCESS" || p.status === "Prepare"
                ).length
              }
            </>
          );
        } else {
          return <>0</>;
        }
      },
    },
    {
      title: "취소 건수",
      dataIndex: "3",
      render: (text, row: any, index) => {
        if (row[1]) {
          let rowdata: any = Object.values(row[1]);
          return <>{rowdata.filter((p) => p.status === "Cancel").length}</>;
        } else {
          return <>0</>;
        }
      },
    },
    {
      title: "완료 건수",
      dataIndex: "4",
      render: (text, row: any, index) => {
        if (row[1]) {
          let rowdata: any = Object.values(row[1]);
          return <>{rowdata.filter((p) => p.status === "DONE").length}</>;
        } else {
          return <>0</>;
        }
      },
    },
    {
      title: "주문 금액",
      dataIndex: "item",
      render: (text, row: any, index) => {
        if (row[1]) {
          let rowdata: any = Object.values(row[1]);
          let allprice = 0;
          rowdata
            .filter((p) => p.status === "DONE")
            .map((d) => {
              allprice = +(allprice + Number.parseInt(d.price));
            });
          return <>{addComma(allprice)}</>;
        } else {
          return <>0</>;
        }
      },
    },
  ];

  return <Table columns={columns} dataSource={data.data} />;
};
