import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo1.svg";
import "./index.css";

import {
  BarChartOutlined,
  BellOutlined,
  DollarOutlined,
  HomeOutlined,
  NotificationOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "antd/es/menu/MenuItem";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/temple">사찰 관리</Link>, "temple", <HomeOutlined />),
  {
    type: "divider",
  },
  getItem(
    <Link to="/sectadd">종파 관리</Link>,
    "sectadd",
    <UsergroupAddOutlined />
  ),
  {
    type: "divider",
  },
  getItem("주문 관리", "order", <DollarOutlined />, [
    getItem(<Link to="/order">주문 관리</Link>, "order", <DollarOutlined />),
  ]),
  {
    type: "divider",
  },
  getItem("통계", "staticsTemple", <BarChartOutlined />, [
    getItem(<Link to="/statics">통계</Link>, "statics", <BarChartOutlined />),
    getItem(
      <Link to="/staticsTemple">사찰별 통계</Link>,
      "staticsTemple",
      <BarChartOutlined />
    ),
  ]),
  {
    type: "divider",
  },
  getItem("유저 관리", "user", <UserOutlined />, [
    getItem(<Link to="/user">유저 관리</Link>, "user", <UserOutlined />),
  ]),
  {
    type: "divider",
  },
  getItem("공지사항", "notice", <BellOutlined />, [
    getItem(<Link to="/notice">공지사항</Link>, "notice", <BellOutlined />),
  ]),
  {
    type: "divider",
  },
  getItem("배너관리", "banner", <NotificationOutlined />, [
    getItem(
      <Link to="/banner">배너관리</Link>,
      "banner",
      <NotificationOutlined />
    ),
  ]),
];

const userItems: MenuItem[] = [
  getItem(<Link to="/temple">사찰 관리</Link>, "temple", <HomeOutlined />),
  {
    type: "divider",
  },

  getItem("주문 관리", "order", <DollarOutlined />, [
    getItem(<Link to="/order">주문 관리</Link>, "order", <DollarOutlined />),
  ]),
  {
    type: "divider",
  },
  getItem("통계", "statics", <DollarOutlined />, [
    getItem(<Link to="/statics">통계</Link>, "statics", <DollarOutlined />),
  ]),
  {
    type: "divider",
  },
];

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

export const SideMenu: React.FC = () => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  var items_new;

  useEffect(() => {
    const pathname = window.location.pathname;
    const splitPathName = pathname.split(/(?=\/)/g); // '/'기준으로 위치 나누기

    const openKey =
      splitPathName.length === 3
        ? [`${splitPathName[0]}`, `${splitPathName[0]}${splitPathName[1]}`] //오픈된 메뉴가 2단의 메뉴인 경우
        : [`${splitPathName[0].replace("/", "")}`]; //1단만 열려 있는경우
    setOpenKeys(openKey);
  }, []);

  window.localStorage.getItem("isStaff") === "true"
    ? (items_new = items)
    : (items_new = userItems);

  return (
    <Sider
      className="site-layout-background"
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {}}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="profile">
        <Logo className="logo" />

        <div className="welcome">
          {window.sessionStorage.getItem("username")}님
          <br />
          환영합니다
        </div>
      </div>

      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[window.location.pathname.replace("/", "")]}
        style={{ height: "100%", borderRight: 0 }}
        items={items_new}
      />
    </Sider>
  );
};

export default SideMenu;
