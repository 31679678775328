import React, { useCallback } from "react";
import { Button, Select, Table } from "antd";
import { UserItemRs, UserStatus } from "../../model/userRs";
import adminAxios from "../../utils/adminAxios";

const handleChangeTemple = (id, column) => (value) => {
  const result = window.confirm("변경 하시겠습니까?");
  if (result) {
    if (column === "type") {
      adminAxios({
        method: "patch",
        url: "user/" + id + "/",
        data: {
          type: value,
        },
      })
        .then((res) => {
          //const data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (column === "status") {
      adminAxios({
        method: "patch",
        url: "user/" + id + "/",
        data: {
          status: value,
        },
      })
        .then((res) => {
          //const data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
const columns = (onApprove: (userId: string) => void) => {
  return [
    // {
    //   title: "아이디",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "아이디",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "전화번호",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "계좌번호",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "승인 여부",
      dataIndex: "status",
      key: "status",
      render: (id, record) => (
        <Select
          defaultValue={record.status}
          style={{
            width: 200,
          }}
          key={id}
          onChange={handleChangeTemple(record.id, "status")}
          options={[
            {
              label: "승인여부",
              options: [
                {
                  label: "승인",
                  value: "APPROVED",
                },
                {
                  label: "비승인",
                  value: "PENDING",
                },
              ],
            },
          ]}
        />
      ),
    },
    {
      title: "타입",
      dataIndex: "type",
      key: "type",
      render: (id, record) => (
        <Select
          defaultValue={record.type}
          style={{
            width: 200,
          }}
          onChange={handleChangeTemple(record.id, "type")}
          options={[
            {
              label: "권한",
              options: [
                {
                  label: "이용자",
                  value: "Normal",
                },
                {
                  label: "매니저",
                  value: "Manager",
                },
                {
                  label: "관리자",
                  value: "Admin",
                },
              ],
            },
          ]}
        />
      ),
    },
    {
      title: "액션",
      dataIndex: "status",
      key: "action",
      render: (status: UserStatus, record: UserItemRs) => {
        if (status === UserStatus.PENDING) {
          return (
            <Button type="primary" onClick={() => onApprove(record.id)}>
              승인
            </Button>
          );
        }
        return null;
      },
    },
  ];
};

interface Props {
  data: UserItemRs[];

  onApprove(userId: string): void;
}

export const UserList: React.FC<Props> = ({ data, onApprove }) => {
  const handleApprove = useCallback(
    (userId: string) => {
      onApprove(userId);
    },
    [onApprove]
  );

  return <Table columns={columns(handleApprove)} dataSource={data} />;
};
