import React, { useCallback, useEffect } from "react";
import { Form } from "antd";
import NoticeForm from "./NoticeForm";
import { NoticeItemRs } from "../../model/noticesRs";
import adminAxios from "../../utils/adminAxios";
import { NoticeModifyRq } from "../../model/noticeModifyRq";
import { useNavigate } from "react-router-dom";

interface Props {
  data: NoticeItemRs | null;
}

const NoticeModifyForm: React.FC<Props> = ({ data }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleConfirm = useCallback(
    async (saveData: NoticeModifyRq) => {
      try {
        if (data?.id) {
          await adminAxios.put(`notice/${data.id}/`, saveData);
          alert("공지사항 수정에 성공하였습니다.");

          navigate(`/notice/${data.id}`);
        }
      } catch {
        alert("공지사항 수정에 실패하였습니다.");
      }
    },
    [navigate, data]
  );

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  return (
    <NoticeForm
      form={form}
      confirmText={"수정완료"}
      onConfirm={handleConfirm}
    />
  );
};

export default NoticeModifyForm;
