import "antd/dist/reset.css";
import { useEffect } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StyledThemeProvider } from "./definitions/styled-components";
import Home from "./pages";
import store from "./redux/store";

function App(): JSX.Element {
  const queryClient = new QueryClient();
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <BrowserRouter>
      <StyledThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <div className="App">
              <Home />
            </div>
          </Provider>
        </QueryClientProvider>
      </StyledThemeProvider>
    </BrowserRouter>
  );
}

export default App;
