import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import adminAxios from "../../utils/adminAxios";
import { UploadFile } from "antd/lib/upload/interface";
import BannerForm from "./BannerForm";
import { BannerItem } from "../../model/bannerRs";
import { BannerRq } from "../../model/bannerRq";

interface Props {
  data: BannerItem | null;
}

const BannerModifyForm: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleConfirm = useCallback(
    async (rq: BannerRq) => {
      try {
        if (data?.id) {
          await adminAxios.patch(`banner/${data.id}/`, rq);

          alert("배너 수정에 성공하였습니다.");
          navigate(`/banner`);
        }
      } catch {
        alert("배너 수정에 실패하였습니다.");
      }
    },
    [data?.id, navigate]
  );

  useEffect(() => {
    if (data) {
      setFileList(
        data.bannerimage.map((img) => ({
          uid: img.id,
          name: img.id,
          url: img.url,
          id: img.id,
        })) ?? []
      );
      form.setFieldsValue(data);
    }
  }, [data, form]);

  return (
    <BannerForm
      form={form}
      fileList={fileList}
      confirmText="수정완료"
      onConfirm={handleConfirm}
      onFileUpload={(files) => setFileList([...(fileList ?? []), ...files])}
      onFileRemove={(file) => {
        setFileList(fileList?.filter((v) => v.uid !== file.uid) ?? []);
      }}
    />
  );
};

export default BannerModifyForm;
