import { TempleItemOption } from "../../../../model/temple";
import { Button, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export function renderTreeItemOption(
  option: TempleItemOption,
  groupIdx: number,
  itemIdx: number,
  optionGroupIdx: number,
  optionIdx: number,
  onOptionDelete: (optionIdx: number) => void,
  afterChange: () => void
) {
  return {
    key: `${groupIdx}-${itemIdx}-${optionGroupIdx}-${optionIdx}`,
    title: (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 80 }}>옵션명 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            value={option.name}
            placeholder={"옵션명을 입력해주세요"}
            onChange={(e) => {
              option.name = e.target.value ?? "";
              afterChange();
            }}
          />
          <Button
            style={{ marginLeft: 10, flexShrink: "0" }}
            onClick={() => {
              onOptionDelete(optionGroupIdx);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </div>
        <div style={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <div style={{ flexShrink: "0", width: 80 }}>옵션 가격 :</div>
          <Input
            style={{ width: 200, flexShrink: 0 }}
            type={"number"}
            placeholder={"옵션 가격을 입력해주세요"}
            value={option.price}
            onChange={(e) => {
              const price = parseInt(e.target.value ?? "");
              option.price = isFinite(price) && !isNaN(price) ? price : null;
              afterChange();
            }}
          />
        </div>
      </div>
    ),
    isLeaf: true,
  };
}
