import React, { useCallback, useEffect, useState } from "react";
import { Layout, Breadcrumb, Input, Tabs, TabsProps } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { UserList } from "../components/user/UserList";
import { UsersRs } from "../model/userRs";
import { UserApproveRq } from "../model/userApproveRq";
import { SearchOutlined } from "@ant-design/icons";
import { UserStatus } from "../model/userRs";

const { Content } = Layout;

const User: React.FC = () => {
  const [data, setData] = useState([]);
  const [alldata, setallData] = useState([]);

  const readList = useCallback(() => {
    adminAxios.get<UsersRs>("user?limit=500").then((rs) => {
      console.log(rs.data);
      setData(rs.data.results ?? []);
      setallData(rs.data.results ?? []);
    });
  }, []);

  const filter = (event) => {
    console.log(event.target.value);
    event.target.value === ""
      ? readList()
      : setData(
          alldata.filter(
            (p) =>
              p.username?.includes(event.target.value) ||
              p.name?.includes(event.target.value) ||
              p.phone?.includes(event.target.value)
          )
        );
  };

  const handleApprove = useCallback((user_id: string) => {
    adminAxios
      .patch("user/", { user_id, approved: true } as UserApproveRq)
      .then(() => {
        alert("가입 승인되었습니다.");
        readList();
      })
      .catch(() => {
        alert("가입 승인에 실패하였습니다.");
      });
  }, []);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `전체`,
      children: <UserList data={data} onApprove={handleApprove} />,
    },
    {
      key: "2",
      label: `승인대기중`,
      children: (
        <UserList
          data={data.filter((p) => p.status === UserStatus.PENDING)}
          onApprove={handleApprove}
        />
      ),
    },
  ];

  useEffect(() => {
    readList();
  }, [readList]);

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>유저</Breadcrumb.Item>
          <Breadcrumb.Item>유저리스트</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          color="#000"
          tabBarStyle={{ color: "#000" }}
        />
        <Input
          type="text"
          maxLength={20}
          className="search_input"
          name="search"
          style={{
            margin: 10,
            width: "15%",
            float: "right",
          }}
          onChange={filter}
          prefix={<SearchOutlined />}
        />
      </Content>
    </PageContainer>
  );
};

export default User;
