import React, { useCallback } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import adminAxios from "../../utils/adminAxios";
import SectForm from "./SectForm";
import { SectRq } from "../../model/sectRq";

interface Props {}

const SectAddForm: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleConfirm = useCallback(
    async (data: SectRq) => {
      try {
        await adminAxios.post("buddhism/sect/", data);

        alert("종파 추가에 성공하였습니다.");
        navigate("/temple");
      } catch {
        alert("종파 추가에 실패하였습니다.");
      }
    },
    [navigate]
  );

  return (
    <SectForm form={form} confirmText={"추가"} onConfirm={handleConfirm} />
  );
};

export default SectAddForm;
