import React, { useCallback, useState } from "react";
import { Form, UploadFile } from "antd";
import adminAxios from "../../utils/adminAxios";
import { useNavigate } from "react-router-dom";
import BannerForm from "./BannerForm";

import { BannerRq } from "../../model/bannerRq";

const BannerAddForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleConfirm = useCallback(
    async (rq: BannerRq) => {
      try {
        await adminAxios.post("banner/", rq);
        alert("배너 추가에 성공하였습니다.");
        navigate("/banner");
      } catch {
        alert("배너 추가에 실패하였습니다.");
      }
    },
    [navigate]
  );

  return (
    <BannerForm
      form={form}
      fileList={fileList}
      confirmText={"추가"}
      onConfirm={handleConfirm}
      onFileUpload={(files) => setFileList([...(fileList ?? []), ...files])}
      onFileRemove={(file) => {
        setFileList(fileList?.filter((v) => v.uid !== file.uid) ?? []);
      }}
    />
  );
};

export default BannerAddForm;
