import React, { useCallback, useEffect, useState } from "react";
import { Layout, Breadcrumb, Button } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { BannerList } from "../components/banner/BannerList";
import { BannerRs } from "../model/bannerRs";
import { UserApproveRq } from "../model/userApproveRq";
import { BreadComp } from "../components";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Banner: React.FC = () => {
  const [data, setData] = useState([]);

  const readList = useCallback(() => {
    adminAxios.get<BannerRs>("banner/").then((rs) => {
      setData(rs.data.results ?? []);
    });
  }, []);

  const handleRefresh = useCallback(() => {
    readList();
  }, [readList]);
  useEffect(() => {
    readList();
  }, [readList]);

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="배너"
          firstlink="/banner"
          second="배너 리스트"
        ></BreadComp>
        <Button style={{ marginBottom: 10 }} onClick={handleRefresh}>
          새로고침
        </Button>
        <Link to="/banneradd" className="dummy" style={{ float: "right" }}>
          <Button style={{ marginBottom: 10 }}>배너 추가</Button>
        </Link>
        <BannerList
          data={data}
          onDelete={() => {
            //readNotices();
            readList();
          }}
        />
      </Content>
    </PageContainer>
  );
};

export default Banner;
