import React, { useState } from "react";
import { Form, Input, Button, FormInstance, Upload } from "antd";
import adminAxios from "../../utils/adminAxios";

import { UploadFile } from "antd/lib/upload/interface";
import { ImageUploadRs } from "../../model/imageUploadRs";
import { BannerRq } from "../../model/bannerRq";

const HEADER = { "Content-Type": "multipart/form-data" };
interface Props {
  form: FormInstance;
  fileList: UploadFile[];
  confirmText: string;
  onConfirm(rq: BannerRq): Promise<void>;
  onFileUpload(file: UploadFile[]): void;
  onFileRemove(file: UploadFile): void;
}

const BannerForm: React.FC<Props> = ({
  form,
  fileList,
  confirmText,
  onConfirm,
  onFileUpload,
  onFileRemove,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onFinish = (values: {
    title?: string;
    content?: string;
    linkurl?: string;
  }) => {
    setIsDisabled(true);
    const { title = "", content = "", linkurl = "" } = values;

    const rq: BannerRq = {
      title,
      content,
      linkurl,
      bannerimage:
        fileList?.map((file) => ({
          id: file["id"] ?? file.uid,
        })) ?? [],
    };

    onConfirm(rq).finally(() => {
      setIsDisabled(false);
    });
  };

  return (
    <Form.Provider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="제목"
          name="title"
          rules={[{ required: true, message: "제목을 입력해주세요" }]}
        >
          <Input placeholder={"제목"} />
        </Form.Item>

        <Form.Item
          label="내용"
          name="content"
          rules={[{ required: true, message: "내용 입력해주세요" }]}
        >
          <Input.TextArea placeholder={"내용"} />
        </Form.Item>
        <Form.Item
          label="Youtube Link"
          name="linkurl"
          rules={[{ required: true, message: "링크를 입력해주세요" }]}
        >
          <Input.TextArea placeholder={"Youtube Link"} />
        </Form.Item>
        <Form.Item
          label="배너 이미지"
          rules={[{ required: true, message: "이미지를 선택해주세요" }]}
        >
          <Upload
            name="logo"
            listType="picture"
            accept="image/*"
            fileList={fileList}
            customRequest={async (options) => {
              const frm = new FormData();
              frm.set("images", options.file);

              const rs = await adminAxios.post<ImageUploadRs>(
                "common/image/",
                frm,
                { headers: HEADER, params: { parents: "banner" } }
              );

              onFileUpload(
                rs.data?.map((img) => ({
                  uid: img.id,
                  id: img.id,
                  url: img.url,
                  name: img.id,
                }))
              );
            }}
            onRemove={(file) => {
              onFileRemove(file);
            }}
            multiple
          >
            <Button>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {confirmText}
          </Button>
        </Form.Item>
      </Form>
    </Form.Provider>
  );
};

export default BannerForm;
