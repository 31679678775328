import React from "react";
import { Button, Table } from "antd";
import { Link } from "react-router-dom";

export const TempleList = ({ data = [], onDeleteTemple = (id) => {} }) => {
  const handleDeleteTemple = (id) => {
    onDeleteTemple(id);
  };

  const columns = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      render: (text, row, index) => (
        <Link to={`/templemodify/${row.id}`}>{text}</Link>
      ),
    },
    {
      title: "지역",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "주소",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "삭제",
      dataIndex: "act",
      key: "act",
      render: (id, record) => (
        <div>
          <Button
            type="primary"
            onClick={() => handleDeleteTemple(record.id)}
            danger
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];
  //<Link to={`templepatch/${record.id}`}><Button type="primary">수정</Button></Link>
  return <Table columns={columns} dataSource={data} />;
};
