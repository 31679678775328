import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Layout } from "antd";
import { PageContainer } from "../components/common/PageContainer";
import NoticeModifyForm from "../components/notice/NoticeModifyForm";
import adminAxios from "../utils/adminAxios";
import { NoticeItemRs } from "../model/noticesRs";

const NoticeModify: React.FC = () => {
  const [data, setData] = useState<NoticeItemRs | null>(null);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();

    adminAxios.get<NoticeItemRs>(`notice/${id}`).then((rs) => {
      setData(rs.data);
    });
  }, []);

  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>공지사항</Breadcrumb.Item>
          <Breadcrumb.Item>공지사항수정</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <NoticeModifyForm data={data} />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default NoticeModify;
