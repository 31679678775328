import { TempleItemGroup } from "../../../../model/temple";
import { renderTreeItem } from "./treeItemRenderer";
import { Button, Input } from "antd";
import {
  DeleteOutlined,
  FolderAddFilled,
  FolderAddOutlined,
  FolderFilled,
} from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";

export function renderTreeItemGroup(
  group: TempleItemGroup,
  groupIdx: number,
  itemFileLists: UploadFile[][],
  afterChange: () => void,
  onGroupDelete: (groupIdx: number) => void,
  onItemFileUpload: (
    groupIdx: number,
    itemIdx: number,
    file: UploadFile[]
  ) => void,
  onItemFileDelete: (
    groupIdx: number,
    itemIdx: number,
    file: UploadFile
  ) => void
) {
  const handleItemDelete = (itemIdx: number) => {
    group?.items?.splice(itemIdx, 1);
    afterChange();
  };

  const handleItemFileUpload = (itemIdx: number, file: UploadFile[]) => {
    onItemFileUpload(groupIdx, itemIdx, file);
  };

  const handleItemFileRemove = (itemIdx: number, file: UploadFile) => {
    onItemFileDelete(groupIdx, itemIdx, file);
  };

  return {
    key: `${groupIdx}`,
    switcherIcon: <FolderFilled />,
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexShrink: "0" }}>아이템 그룹명 :</div>
        <Input
          style={{ marginLeft: 10, width: 200 }}
          value={group.name}
          placeholder={"아이템 그룹명을 입력해주세요"}
          onChange={(e) => {
            group.name = e.target.value ?? "";
            afterChange();
          }}
        />
        <Button
          style={{ marginLeft: 10, flexShrink: "0" }}
          onClick={() => {
            onGroupDelete(groupIdx);
          }}
        >
          <DeleteOutlined style={{ color: "red" }} />
        </Button>
      </div>
    ),
    children: [
      ...(group.items?.map((item, itemIdx) =>
        renderTreeItem(
          item,
          groupIdx,
          itemIdx,
          itemFileLists?.[itemIdx] ?? [],
          afterChange,
          handleItemDelete,
          handleItemFileUpload,
          handleItemFileRemove
        )
      ) ?? []),
      {
        key: `${groupIdx}-item-add`,
        switcherIcon: <FolderAddOutlined />,
        title: (
          <div style={{ paddingBottom: 10 }}>
            <Button
              onClick={() => {
                group.items?.push({
                  name: "새 아이템",
                  price: 0,
                  description: "아이템 설명",
                  images: [],
                  option_groups: [],
                });
                afterChange();
              }}
            >
              아이템 추가
            </Button>
          </div>
        ),
        isLeaf: false,
      },
    ],
    isLeaf: false,
  };
}

export function renderTreeItemGroups(
  groups: TempleItemGroup[],
  groupItemFileLists: UploadFile[][][],
  afterChange: () => void,
  onItemFileUpload: (
    groupIdx: number,
    itemIdx: number,
    file: UploadFile[]
  ) => void,
  onItemFileDelete: (
    groupIdx: number,
    itemIdx: number,
    file: UploadFile
  ) => void
) {
  const handleDelete = (groupIdx: number) => {
    groups.splice(groupIdx, 1);
    afterChange();
  };

  return [
    ...(groups?.map((group, groupIdx) =>
      renderTreeItemGroup(
        group,
        groupIdx,
        groupItemFileLists[groupIdx],
        afterChange,
        handleDelete,
        onItemFileUpload,
        onItemFileDelete
      )
    ) ?? []),
    {
      key: "item-group-add",
      switcherIcon: <FolderAddFilled />,
      title: (
        <Button
          onClick={() => {
            groups?.push({ name: "새 아이템 그룹", items: [] });
            afterChange();
          }}
        >
          아이템 그룹 추가
        </Button>
      ),
      isLeaf: false,
    },
  ];
}
