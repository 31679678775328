import React, { useCallback } from "react";
import { Tree, TreeDataNode } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { TempleItemGroup } from "../../../model/temple";
import { renderTreeItemGroups } from "./renderer/treeItemGroupRenderer";
import { UploadFile } from "antd/lib/upload/interface";

interface Props {
  groups: TempleItemGroup[];
  groupItemFileLists: UploadFile[][][];

  onChange(itemGroups: TempleItemGroup[]): void;

  onItemFileUpload(groupIdx: number, itemIdx: number, file: UploadFile[]): void;

  onItemFileDelete(groupIdx: number, itemIdx: number, file: UploadFile): void;
}

function getKeys(node: TreeDataNode) {
  const keys = [node.key];
  node.children?.forEach((child) => {
    keys.push(...getKeys(child));
  });
  return keys;
}

const TempleItemGroupTree: React.FC<Props> = ({
  groups,
  groupItemFileLists,
  onChange,
  onItemFileDelete,
  onItemFileUpload,
}) => {
  const afterChange = useCallback(() => {
    onChange([...groups]);
  }, [groups, onChange]);

  const treeData = renderTreeItemGroups(
    groups,
    groupItemFileLists,
    afterChange,
    onItemFileUpload,
    onItemFileDelete
  );
  const keys = treeData?.map(getKeys)?.flat() ?? [];

  return (
    <div>
      <Tree
        treeData={treeData}
        switcherIcon={<DownOutlined />}
        selectable={false}
        expandedKeys={keys}
        autoExpandParent
        defaultExpandAll
        showLine
      />
    </div>
  );
};

export default TempleItemGroupTree;
