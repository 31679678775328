import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Tabs } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { OrderList } from "../components/order/OrderList";
import { OrderApi } from "../components/common/commonApi";
import { BreadComp } from "../components";
import { StaticList } from "./../components/statics/StaticsList";

const { Content } = Layout;

const Statics: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    OrderApi().then((res) => {
      const { results = [] } = res;
      var filterdata = results.filter((p) => {
        return p.store.owner === sessionStorage.getItem("userid");
      });
      if (window.localStorage.getItem("isStaff") === "false") {
        setData(filterdata);
      } else {
        setData(results);
      }
    });
  }, []);
  const result_year = data.reduce((acc, curr) => {
    const Convert_date = new Date(curr.date);
    console.log(Convert_date);
    const year = Convert_date.getFullYear();

    if (acc[year]) acc[year].push(curr);
    else acc[year] = [curr];
    return acc;
  }, {});

  const result_month = data.reduce((acc, curr) => {
    const Convert_date = new Date(curr.date);
    const month =
      Convert_date.getFullYear().toString() +
      "-" +
      `${
        Convert_date.getMonth() >= 10
          ? Convert_date.getMonth().toString()
          : "0" + Convert_date.getMonth()
      }`;

    if (acc[month]) acc[month].push(curr);
    else acc[month] = [curr];
    return acc;
  }, {});

  const result_day = data.reduce((acc, curr) => {
    const Convert_date = new Date(curr.date);
    const day =
      Convert_date.getFullYear().toString() +
      "-" +
      `${
        Convert_date.getMonth() >= 10
          ? Convert_date.getMonth().toString()
          : "0" + Convert_date.getMonth()
      }` +
      "-" +
      `${
        Convert_date.getDay() >= 10
          ? Convert_date.getDay().toString()
          : "0" + Convert_date.getDay()
      }`;

    if (acc[day]) acc[day].push(curr);
    else acc[day] = [curr];
    return acc;
  }, {});

  const items = [
    {
      key: "1",
      label: `일자별`,
      children: (
        <StaticList data={Object.entries(result_day)} type="day"></StaticList>
      ),
    },
    {
      key: "2",
      label: `월별`,
      children: (
        <StaticList
          data={Object.entries(result_month)}
          type="month"
        ></StaticList>
      ),
    },
    {
      key: "3",
      label: `연도별`,
      children: (
        <StaticList data={Object.entries(result_year)} type="year"></StaticList>
      ),
    },
  ];

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="통계"
          firstlink="/order"
          second="통계 리스트"
        ></BreadComp>
        <Tabs
          //onChange={onChange}
          type="card"
          items={items}
        />
      </Content>
    </PageContainer>
  );
};

export default Statics;
