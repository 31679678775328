import React from "react";
import { Breadcrumb, Card, Layout } from "antd";
import { PageContainer } from "../components/common/PageContainer";
import NoticeAddForm from "../components/notice/NoticeAddForm";
import { BreadComp } from "../components";

const NoticeAdd: React.FC = () => {
  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="공지사항"
          firstlink="/notice"
          second="공지사항 추가"
        ></BreadComp>
        <Card>
          <NoticeAddForm />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default NoticeAdd;
