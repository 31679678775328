import React from "react";
import { Layout, Card, Breadcrumb } from "antd";
import TempleAddForm from "../components/temple/TempleAddForm";
import { PageContainer } from "../components/common/PageContainer";
import { BreadComp } from "../components";

const { Content } = Layout;

const TempleAdd: React.FC = () => {
  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="사찰 관리"
          firstlink="/temple"
          second="사찰 추가"
        ></BreadComp>
        <Card>
          <TempleAddForm />
        </Card>
      </Content>
    </PageContainer>
  );
};

export default TempleAdd;
