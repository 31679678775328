import axios from "axios";

const defaultOptions = {
  baseURL:
    "http://ec2-13-209-28-211.ap-northeast-2.compute.amazonaws.com:8001/api/",
  //baseURL: "http://210.219.244.78:8000/api/",
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
const adminAxios = axios.create(defaultOptions);

export default adminAxios;
