import adminAxios from "../../utils/adminAxios";

export const OrderApi = async () => {
  return await adminAxios({
    method: "get",
    url: "order/",
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TempleApi = async () => {
  return await adminAxios({
    method: "get",
    url: "buddhism/",
    params: {
      limit: 5000,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
