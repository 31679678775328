export enum UserStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface UserItemRs {
  id: string;
  name: string;
  phone: string;
  type: string;
  status: UserStatus;
  account: string;
}

export interface UsersRs {
  count: number;
  next: string;
  previous: string;
  results: UserItemRs[];
}
