import React, { useEffect, useState } from "react";
import { TempleItemRs } from "../model/templeRs";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { Breadcrumb, Card, Layout } from "antd";
import TempleModifyForm from "../components/temple/TempleModifyForm";
import { BreadComp } from "../components";
import { BannerItem } from "../model/bannerRs";
import BannerModifyForm from "../components/banner/BannerModifyForm";

const BannerModify: React.FC = () => {
  const [data, setData] = useState<BannerItem | null>(null);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();

    adminAxios.get<BannerItem>(`banner/${id}/`).then((rs) => {
      setData(rs.data);
    });
  }, []);

  return (
    <PageContainer>
      <Layout.Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="배너"
          firstlink="/banner"
          second="배너 변경"
        ></BreadComp>
        <Card>
          <BannerModifyForm data={data} />
        </Card>
      </Layout.Content>
    </PageContainer>
  );
};

export default BannerModify;
