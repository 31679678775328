import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Tabs } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { OrderList } from "../components/order/OrderList";
import { OrderApi } from "../components/common/commonApi";
import { BreadComp } from "../components";

const { Content } = Layout;

const Order: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    OrderApi().then((res) => {
      const { results = [] } = res;
      var filterdata = results.filter((p) => {
        return p.store.owner === sessionStorage.getItem("userid");
      });
      if (window.localStorage.getItem("isStaff") === "false") {
        setData(filterdata);
      } else {
        setData(results);
      }
    });
  }, []);

  const items = [
    {
      key: "1",
      label: `전체`,
      children: <OrderList data={data}></OrderList>,
    },
    {
      key: "2",
      label: `접수/진행중`,
      children: (
        <OrderList
          data={data.filter(
            (p) => p.status === "PROCESS" || p.status === "PREPARE"
          )}
        ></OrderList>
      ),
    },
    {
      key: "3",
      label: `취소`,
      children: (
        <OrderList data={data.filter((p) => p.status === "Cancel" || p.status === "REJECT")}></OrderList>
      ),
    },
    {
      key: "4",
      label: `완료`,
      children: (
        <OrderList data={data.filter((p) => p.status === "DONE")}></OrderList>
      ),
    },
  ];

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <BreadComp
          first="주문 관리"
          firstlink="/order"
          second="주문 리스트"
        ></BreadComp>
        <Tabs
          //onChange={onChange}
          type="card"
          items={items}
        />
      </Content>
    </PageContainer>
  );
};

export default Order;
