import React, { useCallback, useEffect, useState } from "react";
import { Layout, Breadcrumb, Card, Button } from "antd";
import adminAxios from "../utils/adminAxios";
import { PageContainer } from "../components/common/PageContainer";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const TempleDetail: React.FC = () => {
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();

  const handleModify = useCallback(() => {
    if (data?.id) {
      navigate(`/templemodify/${data.id}`);
    }
  }, [data?.id, navigate]);

  useEffect(() => {
    const id = window.location.pathname.split("/").pop();

    adminAxios.get(`buddhism/${id}`).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <PageContainer>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>사찰</Breadcrumb.Item>
          <Breadcrumb.Item>사찰상세</Breadcrumb.Item>
        </Breadcrumb>
        <Button style={{ marginBottom: 10 }} onClick={handleModify}>
          수정하기
        </Button>
        <Card title={data?.name}>
          {data.images?.map((image, i) => (
            <React.Fragment key={i}>
              <img src={image.url} alt="사찰이미지" />
              <br />
            </React.Fragment>
          )) ?? []}
          <div>사찰이름: {data?.name}</div>
          <div>사찰ID: {data?.id}</div>
          <div>사찰주소: {data?.address}</div>
          <div>지역: {data?.region}</div>
          <div>
            종파 :{" "}
            <span style={{ color: data?.sect?.color }}>{data?.sect?.name}</span>
          </div>
          <div>주지스님: {data?.chief_monk}</div>
          <div>상품:</div>
          {data?.item_groups?.map((item) => (
            <div style={{ paddingLeft: 40 }} key={item?.id}>
              <div>
                그룹이름 (아이디):
                <b>
                  {item?.name} ({item?.id})
                </b>
              </div>
              <div>아이템 :</div>
              <div style={{ paddingLeft: 40 }}>
                {item?.items?.map((i) => (
                  <div>
                    <div>아이디: {i?.id}</div>
                    <div>이름: {i?.name}</div>
                    <div>설명: {i?.description}</div>
                    <div>가격: {i?.price}</div>
                    <div>
                      <div>이미지 :</div>
                      <div>
                        {i?.images?.map((img, imgIdx) => (
                          <React.Fragment key={imgIdx}>
                            <img src={img.url} alt="아이템이미지" />
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <div>
                      옵션 그룹:
                      <div style={{ paddingLeft: 40 }}>
                        {i?.option_groups?.map((optionGroup) => (
                          <div>
                            <div>옵션그룹id: {optionGroup?.id}</div>
                            <div>옵션그룹이름: {optionGroup?.name}</div>
                            <div>
                              옵션복수선택여부:{" "}
                              {optionGroup?.multiple.toString()}
                            </div>
                            <div>
                              옵션:
                              <div style={{ paddingLeft: 40 }}>
                                {optionGroup?.options?.map((option) => (
                                  <div>
                                    <div>옵션 아이디: {option?.id}</div>
                                    <div>옵션 이름: {option?.name}</div>
                                    <div>옵션 가격: {option?.price}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Card>
      </Content>
    </PageContainer>
  );
};

export default TempleDetail;
