import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import { TempleItemGroup } from "../../model/temple";
import TempleItemGroupTree from "./itemTree/TempleItemGroupTree";
import { TempleRq } from "../../model/templeRq";
import adminAxios from "../../utils/adminAxios";
import { SectItemRs, SectsRs } from "../../model/sectRs";
import { UploadFile } from "antd/lib/upload/interface";
import { ImageUploadRs } from "../../model/imageUploadRs";
import { SearchOutlined } from "@ant-design/icons";
import { useDaumPostcodePopup } from "react-daum-postcode";

const REGIONS = [
  "서울",
  "경기",
  "인천",
  "강원",
  "충북",
  "충남",
  "경북",
  "경남",
  "전북",
  "전남",
  "제주",
];

const HEADER = { "Content-Type": "multipart/form-data" };

interface Props {
  form: FormInstance;
  fileList: UploadFile[];
  itemGroups: TempleItemGroup[];
  itemGroupItemFileLists: UploadFile[][][];

  confirmText: string;

  onConfirm(rq: TempleRq): Promise<void>;

  onItemGroupsChange(itemGroups: TempleItemGroup[]): void;

  onFileUpload(file: UploadFile[]): void;

  onFileRemove(file: UploadFile): void;

  onItemFileUpload(groupIdx: number, itemIdx: number, file: UploadFile[]): void;

  onItemFileDelete(groupIdx: number, itemIdx: number, file: UploadFile): void;
}

const TempleForm: React.FC<Props> = ({
  form,
  fileList,
  itemGroups,
  itemGroupItemFileLists,
  confirmText,
  onConfirm,
  onItemGroupsChange,
  onFileUpload,
  onFileRemove,
  onItemFileUpload,
  onItemFileDelete,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [sects, setSects] = useState<SectItemRs[]>([]);

  useEffect(() => {
    setTimeout(() => {
      adminAxios
        .get<SectsRs>("buddhism/sect/", { params: { limit: 999, offset: 1 } })
        .then((rs) => {
          setSects(rs.data.results);
        });
    }, 3000);
  }, [setSects]);

  const onFinish = (values: {
    name?: string;
    address?: string;
    addressdetail?: string;
    chief_monk?: string;
    region?: string;
    sect?: string;
  }) => {
    setIsDisabled(true);
    const {
      name = "",
      address = "",
      addressdetail = "",
      chief_monk = "",
      region = "",
    } = values;

    const rq: TempleRq = {
      name,
      address,
      addressdetail,
      chief_monk,
      region,
      sect_id: values.sect,
      item_groups: itemGroups,
      images:
        fileList?.map((file) => ({
          id: file["id"] ?? file.uid,
        })) ?? [],
    };

    itemGroupItemFileLists.forEach((items, groupIdx) => {
      items.forEach((itemFiles, itemIdx) => {
        if (rq.item_groups?.[groupIdx]?.items?.[itemIdx]?.images) {
          rq.item_groups[groupIdx].items[itemIdx].images =
            itemFiles?.map((itemFile) => ({
              id: itemFile["id"] ?? itemFile.uid,
            })) ?? [];
        }
      });
    });

    onConfirm(rq).finally(() => {
      setIsDisabled(false);
    });
  };
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let sido = data.sido;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    form.setFieldsValue({ address: fullAddress });
    form.setFieldsValue({ region: sido });
  };

  return (
    <Form.Provider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="사찰이름"
          name="name"
          rules={[{ required: true, message: "사찰이름을 입력해주세요" }]}
        >
          <Input placeholder={"사찰이름"} />
        </Form.Item>
        <Form.Item label="사찰 주소" name="addressgroup">
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                name="address"
                noStyle
                rules={[{ required: true, message: "주소를 입력해주세요" }]}
              >
                <Input disabled placeholder={"주소"} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="middle"
                onClick={handleClick}
                style={{ marginLeft: 10 }}
              >
                검색
              </Button>
            </Col>
          </Row>
        </Form.Item>
        {/* <Form.Item
          label="주소"
          name="address"
          rules={[{ required: true, message: "주소를 입력해주세요" }]}
        >
          <Input placeholder={"주소"} />
        </Form.Item> */}
        <Form.Item
          label="상세 주소"
          name="addressdetail"
          rules={[{ required: true, message: "상세 주소를 입력해주세요" }]}
        >
          <Input placeholder={"상세 주소"} />
        </Form.Item>

        <Form.Item
          label="지역"
          name="region"
          rules={[{ required: true, message: "지역을 선택해주세요" }]}
        >
          <Select
            placeholder={"지역을 선택해주세요"}
            options={REGIONS.map((region) => ({
              value: region,
              label: region,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="종파"
          name="sect"
          rules={[{ required: true, message: "종파를 선택해주세요" }]}
        >
          <Select
            placeholder={"종파를 선택해주세요"}
            options={sects.map((sect) => ({
              value: sect.id,
              label: sect.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="사찰 소개"
          name="chief_monk"
          rules={[{ required: true, message: "사찰 소개를 입력해주세요" }]}
        >
          <Input.TextArea placeholder={"사찰 소개를 입력해주세요"} />
        </Form.Item>

        <Form.Item
          label="사찰 이미지"
          rules={[{ required: true, message: "이미지를 선택해주세요" }]}
        >
          <Upload
            name="logo"
            listType="picture"
            accept="image/*"
            fileList={fileList}
            customRequest={async (options) => {
              const frm = new FormData();
              frm.set("images", options.file);

              const rs = await adminAxios.post<ImageUploadRs>(
                "common/image/",
                frm,
                { headers: HEADER, params: { parents: "buddhism" } }
              );

              onFileUpload(
                rs.data?.map((img) => ({
                  uid: img.id,
                  id: img.id,
                  url: img.url,
                  name: img.id,
                }))
              );
            }}
            onRemove={(file) => {
              onFileRemove(file);
            }}
            multiple
          >
            <Button>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item label="아이템">
          <TempleItemGroupTree
            groups={itemGroups}
            groupItemFileLists={itemGroupItemFileLists}
            onChange={(newItemGroups) => {
              onItemGroupsChange(newItemGroups);
            }}
            onItemFileUpload={onItemFileUpload}
            onItemFileDelete={onItemFileDelete}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {confirmText}
          </Button>
        </Form.Item>
      </Form>
    </Form.Provider>
  );
};

export default TempleForm;
