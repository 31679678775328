import React, { useCallback } from "react";
import { Button, Select, Table } from "antd";
import { UserItemRs, UserStatus } from "../../model/userRs";
import adminAxios from "../../utils/adminAxios";
import { Link } from "react-router-dom";
import { stringify } from "querystring";
import { border } from "styled-system";

const handleChangeTemple = (id, column) => (value) => {
  const result = window.confirm("변경 하시겠습니까?");
  if (result) {
    if (column === "type") {
      adminAxios({
        method: "patch",
        url: "user/" + id + "/",
        data: {
          type: value,
        },
      })
        .then((res) => {
          //const data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (column === "status") {
      adminAxios({
        method: "patch",
        url: "user/" + id + "/",
        data: {
          status: value,
        },
      })
        .then((res) => {
          //const data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
const UrlParamSearch = function (location = "", defaultimage = "") {
  console.log("location >>> ", location);
  try {
    if (location.includes("youtube")) {
      const origin = new URL(location).searchParams;
      let v = origin.get("v");
      let name = "https://img.youtube.com/vi/" + v + "/0.jpg";
      if (v === null) {
        name = defaultimage;
      }
      return name;
    } else {
      return defaultimage;
    }
  } catch {
    return defaultimage;
  }
};
const columns = (onDelete: (id: string) => void) => {
  return [
    // {
    //   title: "아이디",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "이미지",
      dataIndex: "image",
      key: "image",
      render: (text, row, index) => (
        <img
          src={UrlParamSearch(row.linkurl, row.bannerimage[0]?.url)}
          alt={row.title}
          width="200"
          style={{ border: "2px solid #c0c0c0" }}
        />
      ),
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      render: (text, row, index) => (
        <Link to={`/bannermodify/${row.id}`}>{text}</Link>
      ),
    },
    {
      title: "내용",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Link",
      dataIndex: "linkurl",
      key: "linkurl",
      render: (text, row, index) => (
        <a href={row.linkurl} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "삭제",
      dataIndex: "act",
      key: "act",
      render: (id, record) => (
        <div>
          <Button type="primary" onClick={() => onDelete(record.id)} danger>
            삭제
          </Button>
        </div>
      ),
    },
  ];
};

interface Props {
  data: UserItemRs[];

  onDelete(): void;
}

export const BannerList: React.FC<Props> = ({ data, onDelete }) => {
  const handleDelete = useCallback(
    (id: string) => {
      if (window.confirm("삭제하시겠습니까?")) {
        adminAxios.delete(`banner/${id}`).then(() => {
          alert("삭제되었습니다.");
          onDelete();
        });
      }
    },
    [onDelete]
  );

  return <Table columns={columns(handleDelete)} dataSource={data} />;
};
