import React, { useCallback } from "react";
import { Form } from "antd";
import adminAxios from "../../utils/adminAxios";
import { useNavigate } from "react-router-dom";
import NoticeForm from "./NoticeForm";

const NoticeAddForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleConfirm = useCallback(
    async (data: any) => {
      try {
        const { title = "", contents = "" } = data;
        await adminAxios.post("notice/", { title, contents });

        alert("공지사항 추가에 성공하였습니다.");
        navigate("/notice");
      } catch {
        alert("공지사항 추가에 실패하였습니다.");
      }
    },
    [navigate]
  );

  return (
    <NoticeForm form={form} confirmText={"추가"} onConfirm={handleConfirm} />
  );
};

export default NoticeAddForm;
