import React from "react";
import { Table, Button, Tag } from "antd";
import "moment/locale/ko"; // Locale Setting
import moment from "moment";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import adminAxios from "../../utils/adminAxios";

function StatusCheck(status) {
  switch (status) {
    case "Cancel":
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          취소
        </Tag>
      );
    case "REJECT":
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          사찰 반려
        </Tag>
      );
    case "DONE":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          완료
        </Tag>
      );
    case "PROCESS":
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          진행중
        </Tag>
      );
    case "PREPARE":
      return (
        <Tag icon={<SyncOutlined />} color="warning">
          접수
        </Tag>
      );
    default:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          CHECK
        </Tag>
      );
  }
}
const addComma = (price) => {
  let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return returnString;
};
const handleChangeTemple = (id: string, changestatus: string) => {
  if (changestatus === "PROCESS") {
    alert("모바일에서 완료 부탁 드리겠습니다.");
  } else {
    const result = window.confirm("주문 접수 하시겠습니까?");
    if (result) {
      adminAxios({
        method: "patch",
        url: "order/" + id + "/",
        data: {
          status: changestatus,
        },
      })
        .then((res) => {
          alert("주문 접수 완료 되었습니다");
          window.location.reload();
          //const data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};

const columns = [
  {
    title: "사찰",
    dataIndex: "store",
    key: "store",
    render: (text, row, index) => {
      return <>{row.store.name}</>;
    },
  },
  {
    title: "주문 일자",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, row, index) => {
      return <>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</>;
    },
  },
  {
    title: "공양 희망 일자",
    dataIndex: "date",
    key: "date",
    render: (text, row, index) => {
      return <>{moment(row.date).format("YYYY-MM-DD")}</>;
    },
  },
  {
    title: "주문자",
    dataIndex: "user",
    key: "user",
    render: (text, row, index) => {
      return (
        <>
          {row.user.name}({row.user.username})
        </>
      );
    },
  },
  {
    title: "주문 아이템",
    dataIndex: "item",
    key: "item",
    render: (text, row, index) => {
      return (
        <>
          {row.items &&
            row.items.map((data, i) => {
              if (index === 0)
                return (
                  <span>
                    {data.item.name + " (" + addComma(data.item.price) + "원)"}
                  </span>
                );
              else
                return (
                  <span>
                    <br />
                    {data.item.name + " (" + addComma(data.item.price) + "원)"}
                  </span>
                );
            })}
        </>
      );
    },
  },
  {
    title: "상태",
    dataIndex: "status",
    key: "status",
    render: (text, row, index) => {
      return <>{StatusCheck(row.status)}</>;
    },
  },
  {
    title: "가격",
    dataIndex: "price",
    key: "price",
    render: (text, row, index) => {
      return <>{addComma(row.price)} 원 + {"수수료 : " + addComma(5000)} 원</>;
    },
  },
  {
    title: "기도 받는사람",
    dataIndex: "user_comment",
    key: "user_comment",
  },
  {
    title: "상태 변경",
    dataIndex: "action",
    key: "action",
    render: (text, row, index) => {
      if (row.status === "PREPARE") {
        return (
          <>
            <Button
              type="default"
              style={{
                background: "#d0ff00c1",
                borderColor: "white",
                color: "black",
              }}
              onClick={() => handleChangeTemple(row.id, "PROCESS")}
            >
              주문 접수
            </Button>
          </>
        );
      } else if (row.status === "PROCESS") {
        return (
          <>
            <Button
              type="default"
              style={{ background: "#51fc01c1", borderColor: "white" }}
              onClick={() => handleChangeTemple(row.id, "PROCESS")}
            >
              주문 완료
            </Button>
          </>
        );
      }
    },
  },
];

export const OrderList = ({ data = [] }) => {
  return <Table columns={columns} dataSource={data} />;
};
